<template>
  <b-card no-body>
    <b-card-header>
      <!-- title -->
      <b-card-title>{{ $t('dialoguesPerDay') }}</b-card-title>

      <!-- dropdown -->
      <!-- <b-dropdown
        variant="link"
        toggle-class="p-0"
        no-caret
      >
        <template #button-content>
          <feather-icon
            class="text-body"
            icon="MoreVerticalIcon"
          />
        </template>
        <b-dropdown-item>
          Last 28 Days
        </b-dropdown-item>
        <b-dropdown-item>
          Last Month
        </b-dropdown-item>
        <b-dropdown-item>
          Last Year
        </b-dropdown-item>
      </b-dropdown> -->
      <!--/ dropdown -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="heatmap"
        :height="this.height"
        :options="heatMapChart.options"
        :series="this.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle, BDropdown, BDropdownItem } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { EventBus } from "@/views/habit/EventBus.js"
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    VueApexCharts,
    BCardTitle,
    BDropdown,
    BDropdownItem,
  },
  props: {
    dialoguesPerDayData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      heatMapChart: {
        options: {
          tooltip: {
            followCursor: false,
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
              const { images, mood, improvements, recognitions } = w.config.series[seriesIndex].data[dataPointIndex]
              return (
                `
                  <div style="padding: 10px">
                    <div>
                      <span>${w.globals.seriesNames[seriesIndex]} - ${w.globals.labels[dataPointIndex]} </span>
                    </div>
                    <div>
                      <span>${i18n.t('tooltip.dialogues')}: </span>
                      <span style="font-weight: bold">${series[seriesIndex][dataPointIndex]}</span>
                    </div>
                    <div>
                      <span>${i18n.t('tooltip.images')}: </span>
                      <span style="font-weight: bold">${images}</span>
                    </div>
                    <div>
                      <span>${i18n.t('tooltip.mood_answers')}: </span>
                      <span style="font-weight: bold">${mood}</span>
                    </div>
                    <div>
                      <span>${i18n.t('tooltip.improvements')}: </span>
                      <span style="font-weight: bold">${improvements}</span>
                    </div>
                    <div>
                      <span>${i18n.t('tooltip.recognitions')}: </span>
                      <span style="font-weight: bold">${recognitions}</span>
                    </div>
                  </div>
                `
              )
            }
          },
          dataLabels: {
            enabled: true,
            style: {
              colors: ['#ffffff']
            }
          },
          legend: {
            show: false,
            // position: 'bottom',
          },
          // colors: ['#7367F0'],
          stroke: {
            width: 1,
            colors: ['#d3d3d3']
          },
          plotOptions: {
            heatmap: {
              enableShades: false,
              colorScale: {
                ranges: [
                  {
                    from: 0,
                    to: 0,
                    // name: '0-10',
                    color: '#ffffff',
                  },
                  {
                    from: 1,
                    to: 1,
                    // name: '10-20',
                    color: $themeColors.warning,
                  },
                  {
                    from: 2,
                    to: 1000,
                    // name: '10-20',
                    color: $themeColors.success,
                  },
                ],
              },
            },
          },
          xaxis: {
            labels: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            axisTicks: {
              show: true,
            },
          },
          chart: {
            height: 350,
            type: 'heatmap',
            toolbar: {
              show: false,
            },
            events: {
              click: function(event, chartContext, config) {
                if (config.seriesIndex < 0 || config.dataPointIndex < 0) return
                const locationId = config.config.series[config.seriesIndex].locationId
                const date = config.globals.labels[config.dataPointIndex]
                EventBus.$emit("filterByLocation", locationId)
                EventBus.$emit("filterByDate", date)
              },
              dataPointMouseEnter: function(event) {
                event.srcElement.style.cursor = "pointer"
              }
            }
          },
        },
      },
    }
  },
  computed: {
    height() {
      return 120 + 20 * this.dialoguesPerDayData.length
    },
    series() {
      return this.dialoguesPerDayData.slice(0, 50)
    },
  }
}
</script>